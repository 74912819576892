import request from '@/utils/request'
import {GetNToken} from '@/utils/auth'
export function createNewWorkShift(data) {
    return request({
      url: 'workshifts/new',
      method: 'post',
      data: data
    })
}
export function getMyWorkShift(data) {
    return request({
      url: 'workshifts/get-mine',
      method: 'post',
      data: data
    })
}
export function getWorkShiftInfo(data) {
  return request({
    url: 'workshifts/get-info',
    method: 'post',
    data: data
  })
}

export function checkin(data) {
  var ntoken = GetNToken();
  data.ntoken = ntoken;
  return request({
    url: 'workshifts/checkin',
    method: 'post',
    data: data
  })
}
export function checkout(data) {
  var ntoken = GetNToken();
  data.ntoken = ntoken;
  return request({
    url: 'workshifts/checkout',
    method: 'post',
    data: data
  })
}
export function updateWorkShift(data) {
  return request({
    url: 'workshifts/update-info',
    method: 'post',
    data: data
  })
}

export function getWorkShiftsByStore(data) {
  return request({
    url: 'workshifts/get-by-store',
    method: 'post',
    data: data
  })
}
export function deleteWorkShift(data) {
  return request({
    url: 'workshifts/delete',
    method: 'post',
    data: data
  })
}



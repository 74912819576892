<template>
  <v-card class="mx-auto">
    <v-list class="transparent">
      <v-list-item>
        <v-list-item-content>

          <v-list-item-title class="headline mb-1" v-if="shift.checkin_time">{{shift.checkin_time|timeShiftName}}</v-list-item-title>
          <v-list-item-title class="headline mb-1" v-else>{{shift.w_create_at|timeShiftName}}</v-list-item-title>
          <v-list-item-subtitle style="color:#FF9800" v-if="!shift.checkin_time">Chưa Checkin</v-list-item-subtitle>
          <v-list-item-subtitle style="color:#FDD835" v-else-if="!shift.checkout_time">{{shift.check_in_store_name}} - Chưa Checkout</v-list-item-subtitle>
           <v-list-item-subtitle v-else-if="shift.checkin_store_id!==shift.checkout_store_id">{{shift.check_in_store_name}} - <span :style="{
        color:(shift.checkout_store_id>0?'':'orange')
      }">{{shift.check_out_store_name}}</span></v-list-item-subtitle>
           <v-list-item-subtitle v-else>{{shift.check_in_store_name}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item style="min-height: 24px;">
        <v-list-item-title style="color:#009688 ">
          <i class="fas fa-sign-in-alt"></i> {{shift.checkin_time|toHM}}
        </v-list-item-title>
        <v-list-item-title style="color:#01579B" class="text-right">
          {{shift.checkout_time|toHM}}
          <i class="fas fa-sign-out-alt"></i>
        </v-list-item-title>
      </v-list-item>
      <v-list-item style="min-height: 24px;">
        <v-list-item-title > <i class="far fa-clock"></i> Giờ làm: {{shift.w_hours_diff}}</v-list-item-title>
        <v-list-item-title class="text-right" style="color:#004D40" v-if="shift.checkout_time"> <i class="fas fa-clock"></i> Thực tế: <template v-if="shift.w_hours_ad_set===null">
                {{shift.w_hours_diff}}
            </template>
            <template v-else>
                {{shift.w_hours_ad_set}}
            </template></v-list-item-title>
      </v-list-item>
      <v-card-actions>
        <v-spacer></v-spacer>
        <router-link :to="'/workshifts/'+shift.id">
        <v-btn color="primary" small>Chi tiết</v-btn>
        </router-link>
      </v-card-actions>
    </v-list>
  </v-card>
</template>
<script>
export default {
    props:{
        shift:Object
    }
}
</script>
<template>
  <div>
    <d-loading :isLoading="isLoading"></d-loading>

    <v-select
      background-color="white"
      height="45"
      dense=""
      style="width:120px;display: inline-block;margin-right:20px"
      :items="months"
      v-model="selectedMonth"
      label="Tháng"
      outlined
    ></v-select>
    <!-- <v-btn @click="addShiftClick" large color="primary">
      <v-icon small left>fas fa-plus</v-icon>Thêm ca làm việc
    </v-btn> -->
    <v-row>
      <v-col v-for="(item,index) in tableData" :key="index" cols="12" xs="12" sm="6" md="4" lg="3">
        <SingleShiftCard :shift="item"></SingleShiftCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createNewWorkShift, getMyWorkShift } from "@/api/workshifts";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import SingleShiftCard from "./components/SingleShiftCard";
import {monthsBefore} from '@/utils/index.js'

export default {
  components: {
    SingleShiftCard
  },
  data() {
    return {
      months:[],
      isLoading: false,
      tableData: [],
      selectedMonth:null,
    };
  },
  created() {
    this.months = monthsBefore();
    this.selectedMonth = this.months[0].value;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Chấm công", route: "/timekeeping/me" },
      { title: "Của tôi", route: "/timekeeping/me" }
    ]);
    // this.fetchData();
  },
  watch:{
    selectedMonth(){
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      getMyWorkShift({
        month:this.selectedMonth
      })
        .then(res => {
          this.tableData = res.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    addShiftClick() {
      this.isLoading = true;
      createNewWorkShift()
        .then(res => {
          this.$router.push("/workshifts/" + res.data.id);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>